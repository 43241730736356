import React from 'react'
const OurClients = () => {
  return (
    <section
      className='u-clearfix u-image u-section-3'
      id='carousel_c417'
      data-image-width='1669'
      data-image-height='1080'
    >
      <div className='u-clearfix u-sheet u-valign-middle-xs u-sheet-1'>
        <div className='u-clearfix u-expanded-width-sm u-expanded-width-xs u-layout-wrap u-layout-wrap-1'>
          <div className='u-gutter-0 u-layout'>
            <div className='u-layout-row'>
              <div className='u-align-left u-container-style u-layout-cell u-shape-rectangle u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-layout-cell-1'>
                <div className='u-container-layout u-valign-bottom u-container-layout-1'>
                  <div
                    className='u-align-left u-container-style u-group u-opacity u-opacity-50 u-shape-rectangle u-white u-group-1'
                    data-animation-name='customAnimationIn'
                    data-animation-duration='1500'
                    data-animation-delay='250'
                  >
                    <div className='u-container-layout u-valign-middle u-container-layout-2'>
                      <h2 className='u-text u-text-1'>
                        {' '}
                        Where can I get some?
                      </h2>
                      <p className='u-text u-text-2'>
                        Aliquam feugiat consequat sodales. Curabitur suscipit
                        elementum libero sed fermentum. Integer scelerisque
                        libero sed tristique tempus. Cras sit amet commodo erat.
                        Nulla facilisi. Mauris elit ipsum, tempor quis lacinia
                        ac, consequat id orci. Nullam eget ultricies erat. Nam
                        maximus laoreet blandit.
                      </p>
                      <a
                        href='#'
                        className='u-active-palette-4-base u-border-none u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-3-base u-radius-11 u-text-active-white u-text-hover-white u-btn-2'
                        target='_blank'
                      >
                        learn more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='u-align-center-lg u-align-center-xl u-align-left-md u-align-left-sm u-align-left-xs u-container-style u-layout-cell u-shape-rectangle u-size-30-lg u-size-30-xl u-size-60-md u-size-60-sm u-size-60-xs u-layout-cell-2'>
                <div className='u-container-layout u-valign-middle-sm u-valign-middle-xs u-container-layout-3'>
                  <div
                    className='u-carousel u-expanded-width-xs u-gallery u-gallery-slider u-layout-carousel u-lightbox u-show-text-on-hover u-thumbnails-position-bottom u-gallery-1'
                    id='carousel-8c03'
                    data-interval='5000'
                    data-u-ride='carousel'
                  >
                    <div
                      className='u-carousel-inner u-gallery-inner'
                      role='listbox'
                    >
                      <div className='u-active u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-1'>
                        <div
                          className='u-back-slide'
                          data-image-width='1380'
                          data-image-height='920'
                        >
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/aluminum-body-cosmetic-spray-bottle-mockup_358694-4060.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-1'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                      <div className='u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-2'>
                        <div
                          className='u-back-slide'
                          data-image-width='613'
                          data-image-height='626'
                        >
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/four-books-mockup_125540-455.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-2'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                      <div className='u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-3'>
                        <div className='u-back-slide'>
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/business-cards-mockup_1389-1137.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-3'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                      <div className='u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-4'>
                        <div
                          className='u-back-slide'
                          data-image-width='1060'
                          data-image-height='757'
                        >
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/paper-bag-mockup_58466-14965.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-4'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                      <div className='u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-5'>
                        <div
                          className='u-back-slide'
                          data-image-width='720'
                          data-image-height='1080'
                        >
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/pastry-transparent-packaging-view_23-2148961888.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-5'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                      <div className='u-carousel-item u-effect-fade u-effect-hover-zoom u-gallery-item u-carousel-item-6'>
                        <div
                          className='u-back-slide'
                          data-image-width='792'
                          data-image-height='690'
                        >
                          <img
                            className='u-back-image u-expanded'
                            src={`../../assets/jhjhj.jpg`}
                          />
                        </div>
                        <div className='u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-6'>
                          <h3 className='u-gallery-heading'>Sample Title</h3>
                          <p className='u-gallery-text'>Sample Text</p>
                        </div>
                      </div>
                    </div>
                    <a
                      className='u-absolute-vcenter u-carousel-control u-carousel-control-prev u-hover-palette-4-base u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-hover-white u-text-palette-4-base u-white u-carousel-control-1'
                      href='#carousel-8c03'
                      role='button'
                      data-u-slide='prev'
                    >
                      <span aria-hidden='true'> &lt; </span>
                      <span className='sr-only'> &lt; </span>
                    </a>
                    <a
                      className='u-absolute-vcenter u-carousel-control u-carousel-control-next u-hover-palette-4-base u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-hover-white u-text-palette-4-base u-white u-carousel-control-2'
                      href='#carousel-8c03'
                      role='button'
                      data-u-slide='next'
                    >
                      <span aria-hidden='true'> &gt;</span>
                      <span className='sr-only'> &gt;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurClients
