import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Tabs,
  Tab,
  TextField,
  Typography,
  useTheme,
  Button,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import { useDispatch } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import { tokens } from '../../../theme'
import Header from '../../../components/Header'

const tabValues = {
  Tiles3: '3 Tiles',
  Tiles6: '6 Tiles',
  Tiles8: '8 Tiles',
  Tiles10: '10 Tiles',
  Tiles15: '15 Tiles',
  Tiles20: '20 Tiles',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CustomerGiftCardAddTab = ({ onSubmit }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useAuth()

  const params = useParams()

  const [value, setValue] = useState('3Tiles')
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      senderName: user ? user.name : '',
      senderEmail: user ? user.email : '',
      receiverEmail: '',
      noTiles: '',
      amount: '',
      shippingIncluded: '',
      paid: '',
    },
  })

  useEffect(() => {
    setValue(value)
  }, [dispatch, value])

  // making sure the tabs are reactive to the "status" params change in the url
  React.useEffect(() => {
    if (tabValues[params.status] !== undefined) {
      setValue(tabValues[params.status])
    } else {
      setValue('3Tiles')
    }
  }, [params.status])

  return (
    <Box m='20px'>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)'>
        <Box gridColumn='span 12' gridRow='span 1'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Header title='MemoryFrame Gift Card' />
            </Box>
            <Box alignItems='center'>
              <Typography
                variant='h5'
                color={colors.greenAccent[400]}
                sx={{ mb: 1 }}
              >
                Who is the gift for:
              </Typography>
              <TextField
                label='Email Address'
                type='text'
                placeholder='Email Address'
                {...register('receiverEmail', {})}
              />
            </Box>
            <Box
              mt='5px'
              display='flex '
              justifyContent='space-between'
              alignItems='center'
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 2,
                    borderColor: 'divider',
                    marginBottom: '15px',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='Orders'
                  >
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            3 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.4500</Typography>
                        </>
                      }
                      value='3 Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles3')}
                    />
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            6 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.9000</Typography>
                        </>
                      }
                      value='6Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles6')}
                    />
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            8 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.12000</Typography>
                        </>
                      }
                      value='8 Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles8')}
                    />
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            10 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.15000</Typography>
                        </>
                      }
                      value='10 Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles10')}
                    />
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            15 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.22500</Typography>
                        </>
                      }
                      value='15 Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles15')}
                    />
                    <Tab
                      label={
                        <>
                          <Typography variant='caption'>
                            20 Tiles <br />
                          </Typography>
                          <Typography variant='title'>Rs.30000</Typography>
                        </>
                      }
                      value='20 Tiles'
                      onClick={() => navigate('/customer/giftcard/Tiles20')}
                    />
                  </Tabs>
                </Box>
              </TabContext>
            </Box>
            <FormGroup>
              <FormControlLabel
                disabled
                control={<Checkbox defaultChecked />}
                label='Include Shipping Cost'
              />
            </FormGroup>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              open={modalOpen}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modalOpen}>
                <Box sx={style}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Checkout
                  </Typography>

                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: 500,
                          maxWidth: '100%',
                          mt: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label='Your Name'
                          type='text'
                          placeholder='Your Name'
                          {...register('senderName', {})}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: 500,
                          maxWidth: '100%',
                          mt: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label='Your Email'
                          type='text'
                          placeholder='Your Email'
                          {...register('senderEmail', {})}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Button
                    onClick={handleModalClose}
                    fullWidth
                    variant='contained'
                    color='primary'
                    sx={{ mt: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Fade>
            </Modal>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                width: '30%',
                bottom: 0,
                cursor: 'pointer',
                mr: 3,
              }}
              onClick={handleModalOpen}
            >
              <ListItemText primary={'Add Details'} />
            </Button>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                width: '30%',
                bottom: 0,
                cursor: 'pointer',
              }}
              type='Submit'
            >
              <ListItemText primary={'Buy'} />
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerGiftCardAddTab
