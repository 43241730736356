import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import TabContext from '@mui/lab/TabContext'
import { Box, Tabs, Tab, Button, useTheme } from '@mui/material'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import Header from '../../components/Header'
import { tokens } from '../../theme'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import BackspaceIcon from '@mui/icons-material/Backspace'

const tabValues = {
  Open: 'Open',
  Used: 'Used',
  Expired: 'Expired',
}

const PromoCodeTab = ({ modalOpen }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const params = useParams()

  const { user } = useAuth()

  const [value, setValue] = useState('Open')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(value)
  }, [dispatch, value])

  // making sure the tabs are reactive to the "status" params change in the url
  React.useEffect(() => {
    if (tabValues[params.status] !== undefined) {
      setValue(tabValues[params.status])
    } else {
      setValue('Open')
    }
  }, [params.status])

  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='Promo Code' subtitle='List of Promocode' />
        <Box>
          {user.role === 'Admin' && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
              onClick={modalOpen}
            >
              <AddCircleOutlinedIcon sx={{ mr: '10px' }} />
              Add Promo Code
            </Button>
          )}
        </Box>
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)'>
        <Box gridColumn='span 12' gridRow='span 1'>
          <Box
            mt='5px'
            display='flex '
            justifyContent='space-between'
            alignItems='center'
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: 'divider',
                  marginBottom: '15px',
                }}
              >
                <Tabs value={value} onChange={handleChange} aria-label='Orders'>
                  <Tab
                    icon={<BeenhereIcon />}
                    label='Open'
                    value='Open'
                    onClick={() => navigate('/admin/promo-code-list/Open')}
                  />

                  <Tab
                    icon={<BackspaceIcon />}
                    label='Expired'
                    value='Expired'
                    onClick={() => navigate('/admin/promo-code-list/Expired')}
                  />
                </Tabs>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PromoCodeTab
