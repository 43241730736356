import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useEditCustomerOrderMutation,
  useGetCustomerOrdersQuery,
} from '../../../state/orderSlice'
import {
  Box,
  Button,
  Grid,
  TextField,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
  Snackbar,
  Alert,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Header from '../../../components/Header'
import { tokens } from '../../../theme'
import blackframe from '../../../assets/images/black-frame.svg'
import whiteframe from '../../../assets/images/whiteframe.svg'
import nepaliframe from '../../../assets/images/nepali-frame.svg'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
    width: 275,
    height: 275,
    flexShrink: 0,
    animation: 'tile-appear .6s',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  img: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
  },
  imgPadding: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
    padding: '15px',
  },
  classicBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  boldBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  everBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  cleanBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  edgeBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${nepaliframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
}

const CustomerOrder = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  //calling hooks
  const navigate = useNavigate()
  const params = useParams()

  const status = params.status

  const [modalOpen, setModalOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleModalOpen = () => setModalOpen(true)

  const handleModalClose = () => setModalOpen(false)

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/sing-in')
    }
  }

  const {
    data: event = [],
    isLoading: loading,
    error,
  } = useGetCustomerOrdersQuery(status)

  error && logoutHandler(error)

  const [editOrder, { isSuccess: success, error: errorEdit, isError }] =
    useEditCustomerOrderMutation()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  })

  const onSubmit = async (data) => {
    await editOrder({
      orderId: data.id,
      status: 'Closed',
      reason: data.reason,
    }).unwrap()
    handleModalClose()
  }

  useEffect(() => {
    if (errorEdit) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and your order can not be cancelled',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Mention the reason for order cancellation',
      })
      handleClick()
    }

    if (success) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Order has been succfessfully cancelled ',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorEdit, success, isError])

  return (
    <Box sx={{ px: 3 }}>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xl={2} lg={3} md={3} sm={12} xs={12} key={1}>
          {loading ? (
            <>
              <Skeleton animation='wave' variant='h3' />
              <Skeleton animation='wave' variant='body' />
            </>
          ) : (
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={value}
              onChange={handleChange}
              sx={{
                border: 1,
                borderColor: 'divider',
              }}
            >
              {event.map((event, i) => (
                <Tab
                  label={
                    <ListItem
                      key={event.id}
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        margin: '10px 0',
                        borderRadius: '2px',
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: colors.grey[900],
                              fontWeight: 'bold',
                            }}
                          >
                            iD: {event._id}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              color: colors.grey[900],
                              fontWeight: 'bold',
                            }}
                          >
                            Frame Name: {event.image_data[0].frameName}
                          </Typography>
                        }
                      />
                    </ListItem>
                  }
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
          )}
        </Grid>
        {event.map((event, i) => (
          <Grid item xl={10} lg={9} md={9} sm={12} xs={12} key={1}>
            <TabPanel value={value} index={i}>
              <Box>
                {loading ? (
                  <>
                    <Skeleton animation='wave' variant='h3' />
                    <Skeleton animation='wave' variant='body' />
                  </>
                ) : (
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Header
                      title='Order Details'
                      subtitle={`Order ID # ${event?._id}`}
                    />
                    <Button
                      sx={{
                        backgroundColor: 'red',
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '10px 20px',
                      }}
                      onClick={handleModalOpen}
                    >
                      <CancelOutlinedIcon sx={{ mr: '10px' }} />
                      Cancel Order
                    </Button>
                  </Box>
                )}
                {loading ? (
                  <>
                    <Skeleton variant='rectangular' width='100%'>
                      <div style={{ paddingTop: '37%', marginTop: '2%' }} />
                    </Skeleton>
                  </>
                ) : (
                  <>
                    {/* Order Details */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='140px'
                      gap='20px'
                    >
                      {/* ROW 1 */}
                      <Box
                        gridColumn='span 6'
                        gridRow='span 2'
                        backgroundColor={colors.primary[400]}
                      >
                        <Box
                          mt='25px'
                          p='0 30px'
                          display='flex '
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Box>
                            <Typography
                              variant='h5'
                              fontWeight='600'
                              color={colors.grey[100]}
                            >
                              Shipping Details
                            </Typography>
                          </Box>
                        </Box>
                        <Box height='250px'>
                          <Box
                            key='1'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Shipping Cost :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.shippingMethod} - Shipping
                            </Box>
                          </Box>
                          <Box
                            key='2'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Address Line 1:
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.address}, {event?.city},{event?.state},{' '}
                              {event?.country}
                            </Box>
                          </Box>
                          <Box
                            key='3'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Address Line 2 :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.address}, {event?.city},{event?.state},{' '}
                              {event?.country}
                            </Box>
                          </Box>
                          <Box
                            key='4'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Postal Code :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.postalCode}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        gridColumn='span 6'
                        gridRow='span 3'
                        backgroundColor={colors.primary[400]}
                        overflow='auto'
                      >
                        <Box
                          mt='25px'
                          p='0 30px'
                          display='flex '
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Box>
                            <Typography
                              variant='h5'
                              fontWeight='600'
                              color={colors.grey[100]}
                            >
                              Payment Details
                            </Typography>
                          </Box>
                        </Box>
                        <Box height='250px'>
                          <Box
                            key='5'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Payment Option :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.paymentOption}
                            </Box>
                          </Box>
                          <Box
                            key='6'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Payment Status:
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              {event?.paymentStatus}
                            </Box>
                          </Box>
                          <Box
                            key='7'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Total Tiles :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>#1</Box>
                          </Box>
                          <Box
                            key='8'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Per Tiles Cost :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>Rs. 1500</Box>
                          </Box>
                          <Box
                            key='9'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            p='5px'
                          >
                            <Box m=' 0 0 0 20px'></Box>
                            <Box>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Total Cost :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                color={colors.grey[100]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Rs. 1500
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            key='10'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            p='5px'
                          >
                            <Box m=' 0 0 0 20px'></Box>
                            <Box>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Tax Amount :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                color={colors.grey[100]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Rs. 0
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            key='11'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            p='5px'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                          >
                            <Box m=' 0 0 0 20px'></Box>
                            <Box>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Shipping Amount :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                color={colors.grey[100]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Rs. 0
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            key='12'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p='15px 5px 15px 5px'
                          >
                            <Box m=' 0 0 0 20px'></Box>
                            <Box>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Grand Total :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                color={colors.grey[100]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Rs. 1500
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {/* ROW 2 */}
                      <Box
                        gridColumn='span 6'
                        gridRow='span 2'
                        backgroundColor={colors.primary[400]}
                        p='30px'
                        style={{
                          overflow: 'auto',
                        }}
                      >
                        <Typography variant='h5' fontWeight='600'>
                          Ordered Tiles
                        </Typography>
                        {event?.image_data[0]?.imageLink?.map((image, i) => (
                          <Grid item xs={2} key={i}>
                            <div style={styles.imgContainer}>
                              <div
                                style={
                                  `${event?.image_data[0]?.frameName} === 'Classic'`
                                    ? styles.classicBorder
                                    : `${event?.image_data[0]?.frameName} === 'Bold'`
                                    ? styles.boldBorder
                                    : `${event?.image_data[0]?.frameName} === 'Ever'`
                                    ? styles.everBorder
                                    : `${event?.image_data[0]?.frameName} === 'Clean'`
                                    ? styles.cleanBorder
                                    : `${event?.image_data[0]?.frameName} === 'Edge'`
                                    ? styles.edgeBorder
                                    : ''
                                }
                              >
                                <div className='imageCard'>
                                  <img
                                    src={image}
                                    alt={image}
                                    style={
                                      `${event?.image_data[0]?.frameName} === 'Classic'`
                                        ? styles.imgPadding
                                        : `${event?.image_data[0]?.frameName} === 'Bold'`
                                        ? styles.img
                                        : `${event?.image_data[0]?.frameName} === 'Ever'`
                                        ? styles.imgPadding
                                        : `${event?.image_data[0]?.frameName} === 'Clean'`
                                        ? styles.img
                                        : `${event?.image_data[0]?.frameName} === 'Edge'`
                                        ? styles.imgPadding
                                        : ''
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Box>

                      {/* ROW 3 */}
                      <Box
                        gridColumn='span 6'
                        gridRow='span 1'
                        backgroundColor={colors.primary[400]}
                        p='20px 30px'
                      >
                        <Box height='120px'>
                          <Box
                            key='13'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p=' 10px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Order Status :
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              <Button
                                sx={{
                                  backgroundColor: colors.blueAccent[700],
                                  color: colors.grey[100],
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  padding: '5px',
                                }}
                              >
                                {event?.status}
                              </Button>
                            </Box>
                          </Box>
                          <Box
                            key='14'
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p=' 10px'
                          >
                            <Box m=' 0 0 0 20px'>
                              <Typography
                                color={colors.greenAccent[500]}
                                variant='h5'
                                fontWeight='600'
                              >
                                Delivery Status:
                              </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>
                              <Button
                                sx={{
                                  backgroundColor: colors.blueAccent[700],
                                  color: colors.grey[100],
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  padding: '5px',
                                }}
                              >
                                {event?.deliveryStatus}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </TabPanel>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              open={modalOpen}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Fade in={modalOpen}>
                  <Box sx={style}>
                    <Typography
                      id='transition-modal-title'
                      variant='h6'
                      component='h2'
                    >
                      Why would you like to cancel the order?
                    </Typography>
                    <Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: 500,
                            maxWidth: '100%',
                            mt: 2,
                          }}
                        >
                          <TextField
                            fullWidth
                            label='Order Id'
                            type='text'
                            placeholder='Id'
                            {...register('id', {})}
                            defaultValue={event?._id}
                            inputProps={{ readOnly: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: 500,
                            maxWidth: '100%',
                            mt: 2,
                          }}
                        >
                          <TextField
                            fullWidth
                            label='Answer'
                            type='text'
                            placeholder='Answer'
                            multiline
                            rows={3}
                            {...register('reason', { required: true })}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Button
                      type='Submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ mt: 2 }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Fade>
              </form>
            </Modal>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CustomerOrder
