import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Radio from '@mui/material/Radio'
import { pink } from '@mui/material/colors'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@mui/icons-material/Home'
import PaymentIcon from '@mui/icons-material/Payment'
import classic from '../../../assets/images/classic.png'
import clean from '../../../assets/images/clean.png'
import edge from '../../../assets/images/edge.png'
import ever from '../../../assets/images/ever.png'
import bold from '../../../assets/images/bold.png'
import {
  Avatar,
  Button,
  Select,
  Grid,
  ListSubheader,
  TextField,
  MenuItem,
  InputLabel,
} from '@mui/material'
import { useGetCustomerPromoCodesQuery } from '../../../state/promoCodeSlice'

const drawerWidth = 300

let form = null

const SelectFrame = ({ onSubmit, onListClick, numberofTiles, giftId }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [checkout, setCheckout] = useState(1)
  const [placeOrder, setPlaceOrder] = useState(0)
  const [shipping, setshipping] = useState('Free')
  const [open, setOpen] = React.useState(false)
  const [openPay, setOpenPay] = React.useState(false)
  const [paymentValue, setPaymentValue] = React.useState(
    giftId ? giftId : 'cod'
  )
  const [params, setParams] = useState({
    amt: 100,
    psc: 0,
    pdc: 0,
    txAmt: 0,
    tAmt: 100,
    pid: '123awerwefsdrf',
    scd: 'EPAYTEST',
    su: 'http://localhost:3000/customer/my-orderlist',
    fu: 'http://localhost:3000/customer/frame-photo',
  })

  const handleChange = (event) => {
    setPaymentValue(event.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickPayOpen = () => {
    setOpenPay(true)
  }

  const handlePayClose = () => {
    setOpenPay(false)
  }

  const orderSidebar = () => {
    setPlaceOrder(1)
    setCheckout(0)
  }

  const status = 'Open'

  const {
    data: rawPromoCodes = [],
    isLoading: loading,
    error,
  } = useGetCustomerPromoCodesQuery(status)

  const total = 1500 * numberofTiles

  const buttonProps = (value) => ({
    selected: selectedIndex === value,

    onClick: () => {
      setSelectedIndex(value)
      onListClick(value)
    },
  })

  useEffect(() => {
    {
      setParams({
        amt: `${total}`,
        psc: 0,
        pdc: 0,
        txAmt: 0,
        tAmt: `${total}`,
        pid: '123awerwefsdrf',
        scd: 'EPAYTEST',
        su: 'http://localhost:3000/customer/my-orderlist',
        fu: 'http://localhost:3000/customer/frame-photo',
      })
    }
  }, [total])

  useEffect(() => {
    {
      paymentValue === 'wallet' && post()
    }
  }, [paymentValue])

  var path = 'https://uat.esewa.com.np/epay/main'

  const post = () => {
    form = document.createElement('form')
    form.setAttribute('method', 'POST')
    form.setAttribute('action', path)

    for (var key in params) {
      var hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])
      form.appendChild(hiddenField)
    }

    document.body.appendChild(form)
    form.submit()
  }

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      frameName: selectedIndex,
      imageLink: '',
      tilesNumber: numberofTiles,
      shippingMethod: 'Free',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'Nepal',
      paymentOption: paymentValue,
      promoCodeId: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 1,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              zIndex: '1',
            },
          }}
          variant='permanent'
          anchor='right'
        >
          <Toolbar />
          <Divider />
          {placeOrder === 0 && (
            <>
              <List>
                <ListItem
                  key={1}
                  disablePadding
                  onClick={() => setValue('frameName', 1)}
                >
                  <ListItemButton {...buttonProps(1)}>
                    <ListItemIcon>
                      <Avatar
                        alt='Classic'
                        src={classic}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Classic'} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={2}
                  disablePadding
                  onClick={() => setValue('frameName', 2)}
                >
                  <ListItemButton {...buttonProps(2)}>
                    <ListItemIcon>
                      <Avatar
                        alt='Bold'
                        src={bold}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Bold'} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={3}
                  disablePadding
                  onClick={() => setValue('frameName', 3)}
                >
                  <ListItemButton {...buttonProps(3)}>
                    <ListItemIcon>
                      <Avatar
                        alt='Ever'
                        src={ever}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Ever'} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={4}
                  disablePadding
                  onClick={() => setValue('frameName', 4)}
                >
                  <ListItemButton {...buttonProps(4)}>
                    <ListItemIcon>
                      <Avatar
                        alt='Clean'
                        src={clean}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Clean'} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={5}
                  disablePadding
                  onClick={() => setValue('frameName', 5)}
                >
                  <ListItemButton {...buttonProps(5)}>
                    <ListItemIcon>
                      <Avatar
                        alt='Edge'
                        src={edge}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Edge'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
              <List>
                <input
                  type='text'
                  hidden
                  className='form-control form-font'
                  placeholder='Enter issue title'
                  {...register('frameName', {
                    required: true,
                    maxLength: 50,
                    value: { selectedIndex },
                  })}
                />

                <ListItem key={6} disablePadding>
                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{
                      width: '100%',
                      marginX: 2,
                      bottom: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() => orderSidebar()}
                  >
                    <ListItemText primary={'Checkout'} />
                  </Button>
                </ListItem>
              </List>
            </>
          )}
          {checkout === 0 && (
            <>
              <List>
                <ListItem key={11} disablePadding>
                  <ListItemButton onClick={handleClickOpen}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Add Address'} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={12} disablePadding>
                  <ListItemButton onClick={handleClickPayOpen}>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Add Payment Method'} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                subheader={<ListSubheader>Order Details</ListSubheader>}
              >
                <ListItem>
                  <ListItemText
                    primary={`${numberofTiles} tiles`}
                    secondary={`Rs. ${total}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Shipping' secondary={`${shipping}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Total' secondary={`Rs. ${total}`} />
                </ListItem>
              </List>
              <List>
                <Grid item xs={12} md={12} sx={{ padding: 2 }}>
                  <Box autoComplete='off'>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Add Promo Code
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        label='Select Promo Code'
                        id='status'
                        {...register('promoCodeId', {})}
                      >
                        {rawPromoCodes.length > 1 &&
                          rawPromoCodes.map((codes, index) => (
                            <MenuItem value={codes.promoCodeId._id} key={index}>
                              {codes.promoCodeId.promoCodeName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </List>
              <List>
                <ListItem key={13} disablePadding>
                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{
                      width: '100%',
                      marginX: 2,
                      bottom: 0,
                      cursor: 'pointer',
                    }}
                    type='submit'
                  >
                    <ListItemText primary={'Place Order'} />
                  </Button>
                </ListItem>
              </List>
            </>
          )}
        </Drawer>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Add Address'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='Address'
                    id='fullWidth'
                    type='text'
                    placeholder='Street, House No'
                    {...register('address', {})}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='Address 2'
                    id='fullWidth'
                    type='text'
                    placeholder='Address Line(optional)'
                    {...register('address2', {})}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='City'
                    id='fullWidth'
                    type='text'
                    placeholder='Enter city name'
                    {...register('city', {})}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='State'
                    id='fullWidth'
                    type='text'
                    placeholder='Enter State'
                    {...register('state', {})}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='Postal Code'
                    id='fullWidth'
                    type='text'
                    placeholder='Enter postal code'
                    {...register('postalCode', {})}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label='Country'
                    id='fullWidth'
                    type='text'
                    placeholder='Country'
                    {...register('country', {})}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={openPay}
        onClose={handlePayClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {'Payment Method'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={paymentValue}
                onChange={handleChange}
              >
                {giftId ? (
                  <FormControlLabel
                    value={`${giftId}`}
                    control={
                      <Radio
                        sx={{
                          color: pink[800],
                          '&.Mui-checked': {
                            color: pink[600],
                          },
                        }}
                      />
                    }
                    label='Gift Card'
                  />
                ) : (
                  <>
                    <FormControlLabel
                      value='cod'
                      control={
                        <Radio
                          sx={{
                            color: pink[800],
                            '&.Mui-checked': {
                              color: pink[600],
                            },
                          }}
                        />
                      }
                      label='Cash on Delivery'
                    />
                    <FormControlLabel
                      value='wallet'
                      control={
                        <Radio
                          sx={{
                            color: pink[800],
                            '&.Mui-checked': {
                              color: pink[600],
                            },
                          }}
                        />
                      }
                      label='Wallet'
                    />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePayClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default SelectFrame
