import * as React from 'react'
import { Box, Button, useTheme } from '@mui/material'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import Header from '../../components/Header'
import { tokens } from '../../theme'

const AddFAQ = ({ modalOpen }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='FAQ' subtitle='Frequently Asked Questions Page' />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
            }}
            onClick={modalOpen}
          >
            <AddCircleOutlinedIcon sx={{ mr: '10px' }} />
            Add New FAQ's
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddFAQ
