import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Snackbar, Alert } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import { useLoginMutation } from '../../../state/apiSlice'
import { setCredentials } from '../../../state/authSlice'
import { useAuth } from '../../../hooks/useAuth'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import CustomerLoginForm from './form'
import SocialButton from '../../../components/homePage/SocialButton'

const MainDiv = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const MainAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}))

const CustomerLoginScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuth()

  const [errorLoginMessage, setErrorLoginMessage] = useState(null)

  const [snackBarProps, setSnackBarProps] = React.useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (user) {
      navigate(redirect)
    }
  }, [navigate, user, redirect])

  const [login, { isLoading: loadingLogin, error: errorAdd, isError }] =
    useLoginMutation()

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const loginHandler = async (data) => {
    await login(data)
      .unwrap()
      .then((payload) => {
        dispatch(setCredentials(payload))
      })
      .catch((error) => {
        // console.error('rejected', error)
        error && setErrorLoginMessage(error.data.message)
      })
  }

  useEffect(() => {
    if (errorAdd) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong login unsuccessful',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Invalid Email or Password',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorAdd, isError])

  return (
    <Container component='main' maxWidth='xs'>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <MainDiv sx={{ p: 3 }}>
        <MainAvatar>
          <LockOutlinedIcon />
        </MainAvatar>
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <CustomerLoginForm
          loginData={loginHandler}
          errorMessage={errorLoginMessage}
        />
        <SocialButton />
      </MainDiv>
    </Container>
  )
}

export default CustomerLoginScreen
