import React from 'react'
import About from '../components/homePage/About'
import Contact from '../components/homePage/Contact'
import Footer from '../components/homePage/Footer'
import Header from '../components/homePage/Header'
import OurClients from '../components/homePage/OurClients'
import SecondSection from '../components/homePage/SecondSection'
import TopFrame from '../components/homePage/TopFrame'
import './screens.css'

const HomeScreen = () => {
  return (
    <div className='u-body u-xl-mode'>
      <Header />
      <TopFrame />
      <SecondSection />
      <OurClients />
      <About />
      <Contact />
      <Footer />
    </div>
  )
}
export default HomeScreen
