import React from 'react'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, useTheme, Skeleton, Button } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'
import { useGetCustomerGiftCardsQuery } from '../../../state/giftCardSlice'
import RedeemIcon from '@mui/icons-material/Redeem'
import { tokens } from '../../../theme'
import CustomerGiftCardTab from './tab'

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props} style={{ float: 'right' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

const CustomerGiftCardList = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const params = useParams()

  const status = params.status

  // error handling function for ReLogin
  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/sign-in')
    }
  }

  const {
    data: rawGiftCards = [],
    isLoading: loading,
    error,
  } = useGetCustomerGiftCardsQuery(status)

  const editHandler = (data) => {
    navigate(`/customer/frame-photo/${data.row._id}/${data.row.noTiles}`)
  }

  const columns = [
    {
      headerName: 'Sender Name',
      field: 'senderName',
      cellClassName: 'name-column--cell',
    },
    {
      headerName: 'Sender Email',
      field: 'senderEmail',
    },
    {
      headerName: 'No. of Tiles',
      field: 'noTiles',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          Rs {params?.row?.amount}
        </Typography>
      ),
    },
    {
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 'Used' ? (
              <Button variant='contained'>
                Used <RedeemIcon />
              </Button>
            ) : (
              <Button variant='contained' onClick={() => editHandler(params)}>
                Use Gift <RedeemIcon />
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <Box sx={{ px: 3 }}>
      {loading ? (
        <>
          <Skeleton animation='wave' variant='h3' />
          <Skeleton animation='wave' variant='body' />
        </>
      ) : (
        <CustomerGiftCardTab />
      )}
      {loading ? (
        <>
          <Skeleton variant='rectangular' width='100%'>
            <div style={{ paddingTop: '37%', marginTop: '2%' }} />
          </Skeleton>
        </>
      ) : (
        <Box display='flex' justifyContent='space-between'>
          {/* PromoCode */}
          <Box flex='1 1 100%' ml='15px'>
            <Box
              height='75vh'
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .name-column--cell': {
                  color: colors.greenAccent[300],
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: colors.primary[400],
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: colors.blueAccent[700],
                },
                '& .MuiCheckbox-root': {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                autoHeight={true}
                key={rawGiftCards?._id}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                rows={rawGiftCards}
                getRowId={(rawGiftCards) => rawGiftCards?._id}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CustomerGiftCardList
