import React from 'react'
const Contact = () => {
  return (
    <section
      className='u-align-center u-clearfix u-palette-5-dark-3 u-section-6'
      id='carousel_0828'
    >
      <div className='u-clearfix u-sheet u-valign-middle-xl u-sheet-1'>
        <h2 className='u-text u-text-default u-text-1'>Contact Us</h2>
        <h5 className='u-text u-text-default u-text-2'>
          {' '}
          Any questions or remarks? Just write us a message!
        </h5>
        <div className='u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1'>
          <div className='u-layout'>
            <div className='u-layout-row'>
              <div className='u-align-center u-container-style u-layout-cell u-shape-rectangle u-size-33-lg u-size-33-xl u-size-60-md u-size-60-sm u-size-60-xs u-white u-layout-cell-1'>
                <div className='u-container-layout u-valign-top u-container-layout-1'>
                  <img
                    className='u-expanded-width u-image u-image-default u-image-1'
                    src={`../../assets/xccx.jpg`}
                    alt=''
                    data-image-width='1380'
                    data-image-height='920'
                  />
                  <div className='u-expanded-width u-list u-list-1'>
                    <div className='u-repeater u-repeater-1'>
                      <div className='u-container-style u-list-item u-repeater-item'>
                        <div className='u-container-layout u-similar-container u-container-layout-2'>
                          <h6 className='u-text u-text-default u-text-3'>
                            Kathmandu
                          </h6>
                          <p className='u-text u-text-default u-text-4'>
                            {' '}
                            Street Address, Province,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='u-align-center-md u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-xl u-container-style u-layout-cell u-shape-rectangle u-size-27-lg u-size-27-xl u-size-60-md u-size-60-sm u-size-60-xs u-white u-layout-cell-2'
                data-animation-name=''
                data-animation-duration='0'
                data-animation-delay='0'
                data-animation-direction=''
              >
                <div className='u-container-layout u-valign-middle-sm u-valign-middle-xs u-container-layout-5'>
                  <h3 className='u-text u-text-10'>Get in Touch</h3>
                  <p className='u-text u-text-default u-text-11'>
                    {' '}
                    Have an inquiry or some feedbak for us? Fill out the form
                    below to contact our team.{' '}
                  </p>
                  <div className='u-align-left u-expanded-width-xs u-form u-form-1'>
                    <div className='u-form-group u-form-name u-label-top'>
                      <label className='u-label' wfd-invisible='true'>
                        Name
                      </label>
                      <input
                        type='text'
                        placeholder='Enter your Name'
                        name='name'
                        className='u-grey-10 u-input u-input-rectangle u-radius-7 u-input-1'
                        required=''
                      />
                    </div>
                    <div className='u-form-email u-form-group u-label-top'>
                      <label className='u-label' wfd-invisible='true'>
                        Email
                      </label>
                      <input
                        type='email'
                        placeholder='Enter a valid email address'
                        name='email'
                        className='u-grey-10 u-input u-input-rectangle u-radius-7 u-input-2'
                        required=''
                      />
                    </div>
                    <div className='u-form-group u-form-message u-label-top'>
                      <label className='u-label' wfd-invisible='true'>
                        How can we help?
                      </label>
                      <textarea
                        placeholder=''
                        rows='4'
                        cols='50'
                        name='message'
                        className='u-grey-10 u-input u-input-rectangle u-radius-7 u-input-3'
                        required=''
                      ></textarea>
                    </div>
                    <div className='u-align-right u-form-group u-form-submit u-label-top'>
                      <a
                        href='#'
                        className='u-active-palette-4-base u-border-none u-btn u-btn-round u-btn-submit u-button-style u-hover-palette-4-base u-palette-3-base u-radius-7 u-text-active-white u-text-body-alt-color u-text-hover-white u-btn-2'
                      >
                        Submit
                      </a>
                      <input
                        type='submit'
                        value='submit'
                        className='u-form-control-hidden'
                        wfd-invisible='true'
                      />
                    </div>
                    <div
                      className='u-form-send-message u-form-send-success'
                      wfd-invisible='true'
                    >
                      {' '}
                      Thank you! Your message has been sent.{' '}
                    </div>
                    <div
                      className='u-form-send-error u-form-send-message'
                      wfd-invisible='true'
                    >
                      {' '}
                      Unable to send your message. Please fix errors then try
                      again.{' '}
                    </div>
                    <input
                      type='hidden'
                      value=''
                      name='recaptchaResponse'
                      wfd-invisible='true'
                    />
                    <input
                      type='hidden'
                      name='formServices'
                      value='2423364c4ec1ab64a62d635869e0fe3a'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
