import React from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'
import { tokens } from '../../theme'
import '../screens.css'

const MainButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const AdminLoginForm = ({ loginData, errorMessage }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  return (
    <form onSubmit={handleSubmit(loginData)}>
      <TextField
        variant='filled'
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email Address'
        name='email'
        autoComplete='email'
        autoFocus
        {...register('email', {
          required: 'This is required.',
        })}
      />
      {errors.email && <p className='error no-space'>{errors.email.message}</p>}
      <TextField
        variant='filled'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
        {...register('password', {
          required: 'This is required.',
        })}
      />
      {errors.password && (
        <p className='error no-space'>{errors.password.message}</p>
      )}
      <FormControlLabel
        control={
          <Checkbox
            value='remember'
            sx={{
              color: colors.greenAccent[600],
            }}
          />
        }
        label='Remember me'
      />
      <MainButton type='submit' fullWidth variant='contained' color='primary'>
        Sign Up
      </MainButton>
    </form>
  )
}

export default AdminLoginForm
