import React from 'react'
import ReactGoogleLogin from 'react-google-login'
import ReactFacebookLogin from 'react-facebook-login'
import Grid from '@mui/material/Grid'
import { Chip, Divider, Typography } from '@mui/material'

const SocialButton = () => {
  const onResponse = (resp) => {
    console.log(resp)
  }
  return (
    <>
      <Divider style={{ marginTop: '2px', border: '10px black !important' }}>
        <Chip label='Social Login' color='success' />
      </Divider>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={6}>
          <ReactGoogleLogin
            clientId='654124889893-uplrdt88iv4ts7vsagi2ri1sshhkrg7i.apps.googleusercontent.com'
            buttonText='Google'
            className='social-button'
            onSuccess={onResponse}
            onFailure={onResponse}
            icon={false}
            // scope='email'
          />
        </Grid>
        <Grid item xs={6}>
          <ReactFacebookLogin
            appId='462092369407869'
            textButton='Facebook'
            autoLoad={false}
            fields='name,email,picture'
            callback={onResponse}
            onFailure={onResponse}
            cssClass='social-button'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SocialButton
