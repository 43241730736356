import React from 'react'
import { useForm } from 'react-hook-form'
import { Box, Grid, TextField, Button } from '@mui/material'

const FaqForm = ({ onSubmit, cancel, faqData }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: faqData ? faqData.question : '',
      answer: faqData ? faqData.answer : '',
    },
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {faqData && (
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Order Id'
              type='text'
              placeholder='Id'
              {...register('id', {})}
              defaultValue={faqData?._id}
              inputProps={{ readOnly: true }}
            />
          </Box>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Question'
              type='text'
              placeholder='Question'
              {...register('question', {})}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Answer'
              type='text'
              placeholder='Answer'
              multiline
              rows={5}
              {...register('answer', {})}
            />
          </Box>
        </Grid>
      </Grid>
      <Button
        type='Submit'
        fullWidth
        variant='contained'
        color='primary'
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </form>
  )
}

export default FaqForm
