import React from 'react'
import { Navigate } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const rawUser = localStorage.getItem('userInfo')
  const user = rawUser && JSON.parse(localStorage.getItem('userInfo'))

  if (!rawUser) {
    return <Navigate to='/sign-in' />
  }
  if (rawUser && user.role === 'Customer') {
    return <Component />
  }

  if (user.role === 'Admin') {
    return <Navigate to='/admin-login' />
  }

  return <Navigate to='/' />
}
