import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import React, { useContext } from 'react'
import { apiSlice } from '../../state/apiSlice'
import { removeCredentials } from '../../state/authSlice'
import { ColorModeContext, tokens } from '../../theme'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import InputBase from '@mui/material/InputBase'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchIcon from '@mui/icons-material/Search'
import Divider from '@mui/material/Divider'

const Topbar = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const opened = Boolean(anchorEl)
  const { user } = useAuth()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutHandler = () => {
    dispatch(removeCredentials())
    dispatch(apiSlice.util.resetApiState())
    navigate('/admin-login')
  }

  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      {/* SEARCH BAR */}
      <Box
        display='flex'
        backgroundColor={colors.primary[400]}
        borderRadius='3px'
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder='Search' />
        <IconButton type='button' sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display='flex'>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>

        {/* <PersonOutlinedIcon /> */}
        {user && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'right',
                textAlign: 'right',
                float: 'right',
              }}
            >
              <Tooltip title='Account settings'>
                <IconButton
                  onClick={handleClick}
                  size='small'
                  sx={{ ml: 2 }}
                  aria-controls={opened ? 'account-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={opened ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {user ? user.name.substring(0, 1).toUpperCase() : 'M'}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={opened}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 10,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: '#A341F0',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Divider />
              <MenuItem
                onClick={() => {
                  logoutHandler()
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Topbar
