import React from 'react'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, useTheme, Skeleton } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'
import { useGetCustomerPromoCodesQuery } from '../../../state/promoCodeSlice'
import moment from 'moment'
import { tokens } from '../../../theme'
import CustomerPromoCodeTab from './tab'

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props} style={{ float: 'right' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

const CustomerPromoCodeList = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const params = useParams()

  const status = params.status

  function getPromoCodeName(params) {
    return `${params.row.promoCodeId.promoCodeName}`
  }

  function getPromoCode(params) {
    return `${params.row.promoCodeId.promoCode}`
  }

  function getPromoCodeValue(params) {
    return `${params.row.promoCodeId.promoCodeValue}`
  }

  function getPromoCodeDescription(params) {
    return `${params.row.promoCodeId.promoCodeDescription}`
  }

  // error handling function for ReLogin
  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/sign-in')
    }
  }

  const {
    data: rawPromoCodes = [],
    isLoading: loading,
    error,
  } = useGetCustomerPromoCodesQuery(status)

  console.log(rawPromoCodes)

  error && logoutHandler(error)

  const columns = [
    {
      headerName: 'Code Name',
      field: 'promoCodeId.promoCodeName',
      valueGetter: getPromoCodeName,
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      headerName: 'Code',
      field: 'promoCodeId.promoCode',
      valueGetter: getPromoCode,
      flex: 1,
    },
    {
      headerName: 'Code Description',
      field: 'promoCodeId.promoCodeDescription',
      valueGetter: getPromoCodeDescription,
      flex: 1,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          Rs. {params?.row?.promoCodeId?.promoCodeValue}
        </Typography>
      ),
    },
    {
      headerName: 'Expirary Date',
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {moment(params?.row?.promoCodeId?.promoCodeExpiraryDate).format(
            'Do MMM YYYY'
          )}
        </Typography>
      ),
    },
  ]

  return (
    <Box sx={{ px: 3 }}>
      {loading ? (
        <>
          <Skeleton animation='wave' variant='h3' />
          <Skeleton animation='wave' variant='body' />
        </>
      ) : (
        <CustomerPromoCodeTab />
      )}
      {loading ? (
        <>
          <Skeleton variant='rectangular' width='100%'>
            <div style={{ paddingTop: '37%', marginTop: '2%' }} />
          </Skeleton>
        </>
      ) : (
        <Box display='flex' justifyContent='space-between'>
          {/* PromoCode */}
          <Box flex='1 1 100%' ml='15px'>
            <Box
              height='75vh'
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .name-column--cell': {
                  color: colors.greenAccent[300],
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: colors.primary[400],
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: colors.blueAccent[700],
                },
                '& .MuiCheckbox-root': {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                autoHeight={true}
                key={rawPromoCodes?._id}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                rows={rawPromoCodes}
                getRowId={(rawPromoCodes) => rawPromoCodes?._id}
                columns={columns}
                checkboxSelection
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CustomerPromoCodeList
