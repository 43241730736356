import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useDetailOrderQuery,
  useEditOrderMutation,
} from '../../state/orderSlice'
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  Skeleton,
  Snackbar,
  Alert,
  useTheme,
} from '@mui/material'
import { tokens } from '../../theme'
import Header from '../../components/Header'
import blackframe from '../../assets/images/black-frame.svg'
import whiteframe from '../../assets/images/whiteframe.svg'
import nepaliframe from '../../assets/images/nepali-frame.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const styles = {
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
    width: 275,
    height: 275,
    flexShrink: 0,
    animation: 'tile-appear .6s',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  img: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
  },
  imgPadding: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
    padding: '15px',
  },
  classicBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  boldBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  everBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  cleanBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  edgeBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${nepaliframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
}

const OrderDetail = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const params = useParams()

  const navigate = useNavigate()

  const orderId = params.id

  const [modalOpen, setModalOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSnackbarClick = () => {
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/admin-login')
    }
  }

  const {
    data: rawOrders = [],
    isLoading: loading,
    error,
  } = useDetailOrderQuery(orderId)

  error && logoutHandler(error)

  const [
    editOrder,
    { isLoading: loadingEdit, error: errorEdit, isSuccess: success, isError },
  ] = useEditOrderMutation(orderId)

  errorEdit && logoutHandler(errorEdit)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  })

  const {
    register: register1,
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    mode: 'onSubmit1',
  })

  const onSubmit = async (data) => {
    const updateOrder = await editOrder({
      orderId,
      status: data.status,
    }).unwrap()
    handleModalClose()
  }

  const onSubmit1 = async (data) => {
    const updateOrder = await editOrder({
      orderId,
      deliveryStatus: data.deliveryStatus,
    }).unwrap()
    handleClose()
  }

  useEffect(() => {
    if (errorEdit) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and your order can not be updated',
      })
      handleSnackbarClick()
    }

    if (success) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Order has been successfully Updated',
      })
      handleSnackbarClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorEdit, success, isError])

  return (
    <Box m='20px'>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleSnackbarClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      {loading ? (
        <>
          <Skeleton animation='wave' variant='h3' />
          <Skeleton animation='wave' variant='body' />
        </>
      ) : (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Header
            title='Order Details'
            subtitle={`Order ID # ${rawOrders[0]?._id}`}
          />
        </Box>
      )}
      {loading ? (
        <>
          <Skeleton variant='rectangular' width='100%'>
            <div style={{ paddingTop: '37%', marginTop: '2%' }} />
          </Skeleton>
        </>
      ) : (
        <>
          {/* Order Details */}
          <Box
            display='grid'
            gridTemplateColumns='repeat(12, 1fr)'
            gridAutoRows='140px'
            gap='20px'
          >
            {/* ROW 1 */}
            <Box
              gridColumn='span 6'
              gridRow='span 2'
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt='25px'
                p='0 30px'
                display='flex '
                justifyContent='space-between'
                alignItems='center'
              >
                <Box>
                  <Typography
                    variant='h5'
                    fontWeight='600'
                    color={colors.grey[100]}
                  >
                    Shipping Details
                  </Typography>
                </Box>
              </Box>
              <Box height='250px'>
                <Box
                  key='1'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Shipping Cost :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {rawOrders[0]?.shippingMethod} - Shipping
                  </Box>
                </Box>
                <Box
                  key='2'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Address Line 1:
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {rawOrders[0]?.address}, {rawOrders[0]?.city},
                    {rawOrders[0]?.state}, {rawOrders[0]?.country}
                  </Box>
                </Box>
                <Box
                  key='3'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Address Line 2 :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {rawOrders[0]?.address}, {rawOrders[0]?.city},
                    {rawOrders[0]?.state}, {rawOrders[0]?.country}
                  </Box>
                </Box>
                <Box
                  key='4'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Postal Code :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>{rawOrders[0]?.postalCode}</Box>
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn='span 6'
              gridRow='span 3'
              backgroundColor={colors.primary[400]}
              overflow='auto'
            >
              <Box
                mt='25px'
                p='0 30px'
                display='flex '
                justifyContent='space-between'
                alignItems='center'
              >
                <Box>
                  <Typography
                    variant='h5'
                    fontWeight='600'
                    color={colors.grey[100]}
                  >
                    Payment Details
                  </Typography>
                </Box>
              </Box>
              <Box height='250px'>
                <Box
                  key='5'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Payment Option :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {rawOrders[0]?.paymentOption}
                  </Box>
                </Box>
                <Box
                  key='6'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Payment Status:
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {rawOrders[0]?.paymentStatus}
                  </Box>
                </Box>
                <Box
                  key='7'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Total Tiles :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>#1</Box>
                </Box>
                <Box
                  key='8'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Per Tiles Cost :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>Rs. 1500</Box>
                </Box>
                <Box
                  key='9'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  p='5px'
                >
                  <Box m=' 0 0 0 20px'></Box>
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Total Cost :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={colors.grey[100]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Rs. 1500
                    </Typography>
                  </Box>
                </Box>
                <Box
                  key='10'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  p='5px'
                >
                  <Box m=' 0 0 0 20px'></Box>
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Tax Amount :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={colors.grey[100]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Rs. 0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  key='11'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  p='5px'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                >
                  <Box m=' 0 0 0 20px'></Box>
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Shipping Amount :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={colors.grey[100]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Rs. 0
                    </Typography>
                  </Box>
                </Box>
                <Box
                  key='12'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p='15px 5px 15px 5px'
                >
                  <Box m=' 0 0 0 20px'></Box>
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Grand Total :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={colors.grey[100]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Rs. 1500
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* ROW 2 */}
            <Box
              gridColumn='span 6'
              gridRow='span 2'
              backgroundColor={colors.primary[400]}
              p='30px'
              style={{
                overflow: 'auto',
              }}
            >
              <Typography variant='h5' fontWeight='600'>
                Ordered Tiles
              </Typography>
              {rawOrders[0]?.imageId?.imageLink?.map((image, i) => (
                <Grid item xs={2} key={i}>
                  <div style={styles.imgContainer}>
                    <div
                      style={
                        `${rawOrders[0]?.imageId?.frameName} === 'Classic'`
                          ? styles.classicBorder
                          : `${rawOrders[0]?.imageId?.frameName} === 'Bold'`
                          ? styles.boldBorder
                          : `${rawOrders[0]?.imageId?.frameName} === 'Ever'`
                          ? styles.everBorder
                          : `${rawOrders[0]?.imageId?.frameName} === 'Clean'`
                          ? styles.cleanBorder
                          : `${rawOrders[0]?.imageId?.frameName} === 'Edge'`
                          ? styles.edgeBorder
                          : ''
                      }
                    >
                      <div className='imageCard'>
                        <img
                          src={image}
                          alt={image}
                          style={
                            `${rawOrders[0]?.imageId?.frameName} === 'Classic'`
                              ? styles.imgPadding
                              : `${rawOrders[0]?.imageId?.frameName} === 'Bold'`
                              ? styles.img
                              : `${rawOrders[0]?.imageId?.frameName} === 'Ever'`
                              ? styles.imgPadding
                              : `${rawOrders[0]?.imageId?.frameName} === 'Clean'`
                              ? styles.img
                              : `${rawOrders[0]?.imageId?.frameName} === 'Edge'`
                              ? styles.imgPadding
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Box>

            {/* ROW 3 */}
            <Box
              gridColumn='span 6'
              gridRow='span 1'
              backgroundColor={colors.primary[400]}
              p='20px 30px'
            >
              <Box height='120px'>
                <Box
                  key='13'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p=' 10px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Order Status :
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '5px',
                      }}
                      onClick={handleModalOpen}
                    >
                      {rawOrders[0]?.status}
                    </Button>
                  </Box>
                </Box>
                <Box
                  key='14'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p=' 10px'
                >
                  <Box m=' 0 0 0 20px'>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      Delivery Status:
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]} onClick={handleOpen}>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '5px',
                      }}
                    >
                      {rawOrders[0]?.deliveryStatus}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Modal */}
          <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
          >
            <Fade in={modalOpen}>
              <Box sx={style}>
                <Typography
                  id='transition-modal-title'
                  variant='h6'
                  component='h2'
                >
                  Change Order Status
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: 500,
                          maxWidth: '100%',
                          mt: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <Select
                            labelId='demo-simple-select-label'
                            id='status'
                            placeholder='Select Status'
                            defaultValue={rawOrders[0]?.status}
                            {...register('status', {
                              required:
                                'Status is required. Please Select the status',
                              maxLength: {
                                value: 100,
                                message: 'This should be 100 characters only',
                              },
                            })}
                          >
                            <MenuItem value='Open' key='1'>
                              Open
                            </MenuItem>
                            <MenuItem value='OnDelivery' key='2'>
                              On-Delivery
                            </MenuItem>
                            <MenuItem value='Cancelled' key='3'>
                              Cancelled
                            </MenuItem>
                            <MenuItem value='Delivered' key='4'>
                              Delivered
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ mt: 2 }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </form>
              </Box>
            </Fade>
          </Modal>
          {(rawOrders[0]?.status === 'OnDelivery' ||
            rawOrders[0]?.status === 'Delivered') && (
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Change Delivery Status
                  </Typography>

                  <form onSubmit={handleSubmit1(onSubmit1)}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: 500,
                            maxWidth: '100%',
                            mt: 2,
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              labelId='demo-simple-select-label-2'
                              id='deliveryStatus'
                              placeholder='Select Delivery Status'
                              defaultValue={'In-Progress'}
                              {...register1('deliveryStatus', {
                                required:
                                  'Delivery Status is required. Please Select the status',
                                maxLength: {
                                  value: 100,
                                  message: 'This should be 100 characters only',
                                },
                              })}
                            >
                              {rawOrders[0]?.status === 'OnDelivery' && (
                                <MenuItem value='Dispatched' key='1'>
                                  Dispatched
                                </MenuItem>
                              )}
                              {rawOrders[0]?.status === 'OnDelivery' && (
                                <MenuItem value='Warehouse' key='2'>
                                  Warehouse
                                </MenuItem>
                              )}
                              {rawOrders[0]?.status === 'Delivered' && (
                                <MenuItem value='Received' key='3'>
                                  Received
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        sx={{ mt: 2 }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </form>
                </Box>
              </Fade>
            </Modal>
          )}
        </>
      )}
    </Box>
  )
}

export default OrderDetail
