import React from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const MainButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const CustomerResetPassword = ({ onSubmit }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        {...register('password', {
          required: 'This is required.',
        })}
      />
      {errors.email && (
        <p className='error no-space'>{errors.password.message}</p>
      )}

      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='confirmPassword'
        label='Confirm Password'
        type='password'
        id='confirmPassword'
        {...register('confirmPassword', {
          required: 'This is required.',
        })}
      />
      {errors.email && (
        <p className='error no-space'>{errors.confirmPassword.message}</p>
      )}

      <MainButton type='submit' fullWidth variant='contained' color='primary'>
        Update Password
      </MainButton>
    </form>
  )
}

export default CustomerResetPassword
