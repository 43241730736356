import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Box, Grid, Snackbar, Alert } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useAddPhotoFrameMutation } from '../../../state/frameSlice'
import PhotoUpload from './photo-upload'
import SelectFrame from './select-frame'
import blackframe from '../../../assets/images/black-frame.svg'
import whiteframe from '../../../assets/images/whiteframe.svg'
import nepaliframe from '../../../assets/images/nepali-frame.svg'
import './image.css'

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
    width: 275,
    height: 275,
    flexShrink: 0,
    animation: 'tile-appear .6s',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  img: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
  },
  imgPadding: {
    width: '191px',
    height: '191px',
    position: 'absolute',
    border: 'none',
    padding: '15px',
  },
  classicBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  boldBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${blackframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  everBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  cleanBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${whiteframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
  edgeBorder: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${nepaliframe})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: '8px 1px 24px 12px',
  },
}

const PhotoFrameScreen = () => {
  const [images, setImages] = useState([])
  const [imageUrl, setImageUrl] = useState([])
  const [selectedCar, setSelectedCar] = useState(null)
  const [frameValue, setFrameValue] = useState(1)
  const [numberofTiles, setNumberOfTiles] = useState(0)
  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [addPhotoFrame, { isSuccess: success, error, isError }] =
    useAddPhotoFrameMutation()

  const onListClick = (e) => {
    setFrameValue(e)
  }

  const { user } = useAuth()
  const navigate = useNavigate()

  const onCancel = () => {
    setSelectedCar(null)
  }

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = URL.createObjectURL(e.target.files[0])
      const tilesno = numberofTiles + 1
      const id = Math.random()
      setNumberOfTiles(tilesno)
      const newData = {
        id: id,
        name: file,
        croppedImageUrl: null,
      }
      setImages((images) => [...images, newData])
    }
  }

  const setCroppedImageFor = (id, crop, zoom, aspect, croppedImageUrl) => {
    const newImagesList = [...images]
    const imageIndex = images.findIndex((x) => x.id === id)
    const image = images[imageIndex]
    const newCar = { ...image, croppedImageUrl, crop, zoom, aspect }
    newImagesList[imageIndex] = newCar
    setImages(newImagesList)
    setSelectedCar(null)
  }

  const resetImage = (id) => {
    setCroppedImageFor(id)
  }

  const submitHandler = async (data) => {
    if (user) {
      for (let i = 0; i < images.length; i++) {
        let imagedata
        if (images[i].croppedImageUrl === null) {
          imagedata = await axios({
            url: images[i].name,
            method: 'get',
            responseType: 'blob',
          })
        } else {
          imagedata = await axios({
            url: images[i].croppedImageUrl,
            method: 'get',
            responseType: 'blob',
          })
        }

        if (imagedata.status == 200) {
          const reader = new FileReader()
          reader.readAsDataURL(imagedata.data)
          reader.onloadend = async function () {
            var base64data = reader.result

            const formData = new FormData()
            formData.append('file', base64data)
            formData.append('api_key', process.env.CLOUDINARY_API_KEY)
            formData.append('upload_preset', 'blendwit-tech')

            axios({
              method: 'POST',
              url: 'https://api.cloudinary.com/v1_1/blendwit-tech/upload',
              data: formData,
            }).then((res) => imageStore(res.data.url, data))
          }
        }
      }
      // storeOrderData(data)
    } else {
      navigate('/sign-in')
    }
  }

  const imageStore = (image, data) => {
    imageUrl.push(image)
    if (imageUrl.length === images.length) {
      addPhotoFrame({
        frameName: data.frameName,
        imageLink: imageUrl,
        tilesNumber: data.tilesNumber,
        shippingMethod: data.shippingMethod,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country,
        paymentOption: data.paymentOption,
        promoCodeId: data.promoCodeId,
      }).unwrap()
    }
  }

  useEffect(() => {
    if (error) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and your order can not be placed',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Address and Payement method required',
      })
      handleClick()
    }

    if (success) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Your order has been successfully Placed',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [error, success, isError])

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      {selectedCar ? (
        <>
          <PhotoUpload
            id={selectedCar.id}
            imageUrl={selectedCar.name}
            cropInit={selectedCar.crop}
            zoomInit={selectedCar.zoom}
            aspectInit={selectedCar.aspect}
            onCancel={onCancel}
            setCroppedImageFor={setCroppedImageFor}
            resetImage={resetImage}
          />
        </>
      ) : (
        <>
          <Box>
            <Grid container>
              {images?.map((image, i) => (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={i}>
                  <div style={styles.imgContainer}>
                    <div
                      style={
                        frameValue === 1
                          ? styles.classicBorder
                          : frameValue === 2
                          ? styles.boldBorder
                          : frameValue === 3
                          ? styles.everBorder
                          : frameValue === 4
                          ? styles.cleanBorder
                          : frameValue === 5
                          ? styles.edgeBorder
                          : ''
                      }
                    >
                      <div className='imageCard'>
                        <img
                          src={
                            image.croppedImageUrl
                              ? image.croppedImageUrl
                              : image.name
                          }
                          alt={image.id}
                          style={
                            frameValue === 1 ||
                            frameValue === 3 ||
                            frameValue === 5
                              ? styles.imgPadding
                              : styles.img
                          }
                          onClick={() => {
                            console.log(image)
                            setSelectedCar(image)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
              <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                <div className='container'>
                  <div className='btn-container'>
                    <input
                      type='file'
                      accept='image/*'
                      id='file'
                      multiple
                      onChange={onFileChange}
                    />
                    <AddOutlinedIcon sx={{ fontSize: 80 }} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          {images.length > 0 && (
            <SelectFrame
              onListClick={onListClick}
              onSubmit={submitHandler}
              numberofTiles={numberofTiles}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PhotoFrameScreen
