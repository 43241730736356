import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography, Snackbar, Alert, useTheme } from '@mui/material'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Accordion from '@mui/material/Accordion'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { tokens } from '../../theme'
import {
  useAddFaqMutation,
  useEditFaqMutation,
  useGetFaqsQuery,
} from '../../state/faqSlice'
import AddFAQ from './addFaq'
import FaqForm from './form'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const AdminFAQ = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [faqData, setFaqData] = React.useState()
  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const handleSnackbarClick = () => {
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  // error handling function for ReLogin
  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/admin-login')
    }
  }

  const { data: rawFaq = [], isLoading: loading, error } = useGetFaqsQuery()

  error && logoutHandler(error)

  const [
    addFaq,
    {
      isLoading: loadingAdd,
      error: errorAdd,
      isSuccess: successAdd,
      isError: errorAddS,
    },
  ] = useAddFaqMutation()

  const [
    editFaq,
    { isLoading: loadingEdit, error: errorEdit, isSuccess: successEdit },
  ] = useEditFaqMutation()

  const addButtonHandler = () => {
    setFaqData(null) //to remove previous state
    handleModalOpen()
  }

  const editHandler = (data) => {
    setFaqData(data)
    handleModalOpen()
  }

  const submitHandler = async (data) => {
    faqData
      ? await editFaq({
          faqId: data.id,
          question: data.question,
          answer: data.answer,
        }).unwrap()
      : await addFaq({
          question: data.question,
          answer: data.answer,
        }).unwrap()
  }

  useEffect(() => {
    if (errorEdit) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and faq can not be updated',
      })
      handleSnackbarClick()
    }

    if (successEdit) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Faq has been successfully Updated',
      })
      handleSnackbarClick()
    }

    if (errorAdd) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and faq can not be added',
      })
      handleSnackbarClick()
    }
    if (errorAddS) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Please fill all the data to add',
      })
      handleSnackbarClick()
    }
    if (successAdd) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Faq has been successfully added',
      })
      handleSnackbarClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorEdit, successEdit, errorAdd, errorAddS, successAdd])
  return (
    <Box m='20px'>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleSnackbarClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      <AddFAQ modalOpen={addButtonHandler} />
      {rawFaq.map((event, i) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[500]} variant='h5'>
              {event?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={11}>
                <Typography>{event?.answer}</Typography>
              </Grid>
              <Grid item xs={1}>
                <EditOutlinedIcon onClick={() => editHandler(event)} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              {faqData ? 'Edit FAQ' : 'Add FAQ'}
            </Typography>
            <FaqForm
              onSubmit={submitHandler}
              cancel={handleModalClose}
              faqData={faqData}
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default AdminFAQ
