import React, { useEffect } from 'react'
import { Snackbar, Alert } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import CustomerForgetPassword from './form'
import { useForgetPasswordUserMutation } from '../../../state/userSlice'

const MainDiv = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const MainAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}))

const CustomerForgetPasswordScreen = () => {
  const [
    forgetPasswordUser,
    { isLoading: loading, error: error, isSuccess: success, isError },
  ] = useForgetPasswordUserMutation()

  const [snackBarProps, setSnackBarProps] = React.useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const submitHandler = async (data) => {
    await forgetPasswordUser({
      email: data.email,
    }).unwrap()
  }

  useEffect(() => {
    if (error) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and reset link cannot be sent',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Invalid Email address',
      })
      handleClick()
    }

    if (success) {
      setSnackBarProps({
        alertType: 'success',
        message: 'Password Reset link successfully sent',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [error, success, isError])

  return (
    <Container component='main' maxWidth='xs'>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <MainDiv sx={{ p: 3 }}>
        <MainAvatar>
          <LockOutlinedIcon />
        </MainAvatar>
        <Typography component='h2' variant='h5'>
          Forget Password
        </Typography>
        <CustomerForgetPassword onSubmit={submitHandler} />
      </MainDiv>
    </Container>
  )
}

export default CustomerForgetPasswordScreen
