import React, { useEffect, useState } from 'react'
import { useTheme, Skeleton } from '@mui/material'
import { tokens } from '../../theme'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import { useLoginMutation } from '../../state/apiSlice'
import { setCredentials } from '../../state/authSlice'
import { useAuth } from '../../hooks/useAuth'
import AdminLoginForm from './form'
import '../screens.css'

const MainDiv = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1F2A40',
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const MainAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}))

const AdminLoginScreen = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuth()

  const [errorLoginMessage, setErrorLoginMessage] = useState(null)

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/admin/dashboard'

  useEffect(() => {
    if (user) {
      navigate(redirect)
    }
  }, [navigate, user, redirect])

  const [login, { isLoading: loadingLogin }] = useLoginMutation()

  const loginHandler = async (data) => {
    await login(data)
      .unwrap()
      .then((payload) => {
        dispatch(setCredentials(payload))
      })
      .catch((error) => {
        // console.error('rejected', error)
        error && setErrorLoginMessage(error.data.message)
      })
  }

  return (
    <Container maxWidth='xs'>
      <MainDiv sx={{ p: 3 }}>
        <MainAvatar>
          <LockOutlinedIcon />
        </MainAvatar>
        <Typography
          component='h1'
          variant='h4'
          sx={{
            color: colors.primary[900],
            fontWeight: 'bold',
          }}
        >
          Admin Login
        </Typography>
        <AdminLoginForm
          loginData={loginHandler}
          errorMessage={errorLoginMessage}
        />
      </MainDiv>
    </Container>
  )
}

export default AdminLoginScreen
