import React from 'react'
const SecondSection = () => {
  return (
    <section
      className='u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-clearfix u-palette-4-base u-section-2'
      id='carousel_a334'
    >
      <div className='u-clearfix u-sheet u-valign-middle u-sheet-1'>
        <div
          className='u-opacity u-opacity-45 u-shape u-shape-rectangle u-white u-shape-1'
          data-animation-name='customAnimationIn'
          data-animation-duration='1500'
          data-animation-delay='250'
        ></div>
        <img
          className='u-image u-image-1'
          src={`../../assets/pillow-with-lemons-yellow-armchair-interior-room_169016-16555.webp`}
          data-image-width='740'
          data-image-height='1110'
          data-animation-name='customAnimationIn'
          data-animation-duration='1500'
          data-animation-delay='250'
        />

        <div
          className='u-grey-5 u-preserve-proportions u-shape u-shape-circle u-shape-3'
          data-animation-name='customAnimationIn'
          data-animation-duration='1500'
          data-animation-delay='500'
        ></div>
        <div
          className='u-align-left u-container-style u-group u-shape-rectangle u-white u-group-1'
          data-animation-name='customAnimationIn'
          data-animation-duration='1500'
          data-animation-delay='250'
        >
          <div className='u-container-layout u-valign-middle u-container-layout-1'>
            <h2 className='u-text u-text-1'> Why do we use it?</h2>
            <p className='u-text u-text-2'>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <a
              href='#'
              className='u-active-palette-4-base u-border-none u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-3-base u-radius-11 u-text-active-white u-text-hover-white u-btn-2'
              target='_blank'
            >
              learn more
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecondSection
