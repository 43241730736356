import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Skeleton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import { useTheme } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useGetOrdersQuery } from '../../state/orderSlice'
import OrderTab from './tab'

const Order = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  //calling hooks
  const navigate = useNavigate()
  const params = useParams()

  const status = params.status

  function getFrameName(params) {
    return `${params.row.imageId.frameName}`
  }

  function getUserName(params) {
    return `${params.row.imageId.addedUser.name}`
  }

  // error handling function for ReLogin
  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/admin-login')
    }
  }

  const { data: rawOrders = [], isLoading: loading, error } = useGetOrdersQuery(
    status
  )

  error && logoutHandler(error)

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'imageId',
      headerName: 'Image',
      width: 250,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <img
              src={params.value.imageLink}
              alt={params.value.imageLink}
              style={{
                height: '150px',
                width: '150px',
              }}
            />
          </>
        )
      },
    },
    {
      headerName: 'User Name',
      field: 'imageId.addedUser.name',
      valueGetter: getUserName,
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      headerName: 'Frame Name',
      field: 'imageId.frameName',
      valueGetter: getFrameName,
      headerAlign: 'left',
      align: 'left',
    },
    {
      headerName: 'Address',
      field: 'address',
      flex: 1,
    },
    {
      headerName: 'Payment Method',
      field: 'paymentOption',
      flex: 1,
    },
    {
      headerName: 'Shipping Method',
      field: 'shippingMethod',
      flex: 1,
    },
    {
      headerName: 'Paid',
      field: 'paymentStatus',
      flex: 1,
    },
    {
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (rawOrders) => {
        const onClickDetails = (e) => {
          e.stopPropagation() // don't select this row after clicking
          navigate(`/admin/order-details/${rawOrders.id}`)
        }

        return (
          <>
            <VisibilityIcon onClick={onClickDetails} />
          </>
        )
      },
      disableClickEventBubbling: true,
      flex: 1,
    },
  ]

  return (
    <Box m='20px'>
      {loading ? (
        <>
          <Skeleton animation='wave' variant='h3' />
          <Skeleton animation='wave' variant='body' />
        </>
      ) : (
        <OrderTab />
      )}
      {loading ? (
        <>
          <Skeleton variant='rectangular' width='100%'>
            <div style={{ paddingTop: '37%', marginTop: '2%' }} />
          </Skeleton>
        </>
      ) : (
        <Box
          m='40px 0 0 0'
          height='75vh'
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            autoHeight={true}
            density='comfortable'
            key={rawOrders?._id}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            rows={rawOrders}
            getRowId={(rawOrders) => rawOrders?._id}
            columns={columns}
            checkboxSelection
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            rowHeight={90}
          />
        </Box>
      )}
    </Box>
  )
}

export default Order
