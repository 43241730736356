import React from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { Grid, Link } from '@mui/material'

const MainButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const CustomerForgetPassword = ({ onSubmit }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email Address'
        name='email'
        autoFocus
        {...register('email', {
          required: 'This is required.',
        })}
      />
      {errors.email && <p className='error no-space'>{errors.email.message}</p>}

      <MainButton type='submit' fullWidth variant='contained' color='primary'>
        Send Link
      </MainButton>
      <Grid container justifyContent='center'>
        <Grid item>
          <Link onClick={() => navigate('/sign-up')} variant='body2'>
            {'Go back to Sign Up'}
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

export default CustomerForgetPassword
