import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import {
  useGetPromoCodesQuery,
  useAddPromoCodeMutation,
  useEditPromoCodeMutation,
} from '../../state/promoCodeSlice'
import moment from 'moment'
import { tokens } from '../../theme'
import PromoCodeTab from './tab'
import PromoForm from './form'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const PromoCode = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const params = useParams()

  const status = params.status

  const [modalOpen, setModalOpen] = React.useState(false)
  const [promoCode, setPromoCode] = React.useState()
  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const handleSnackbarClick = () => {
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  // error handling function for ReLogin
  const logoutHandler = (error) => {
    //check if error comes from ReLogin then push them to logout screen
    if (
      JSON.parse(JSON.stringify(error.data.message)) === 'User requires login'
    ) {
      navigate('/admin-login')
    }
  }

  const {
    data: rawPromoCodes = [],
    isLoading: loading,
    error,
  } = useGetPromoCodesQuery(status)

  error && logoutHandler(error)

  const [
    addPromoCode,
    {
      isLoading: loadingAdd,
      error: errorAdd,
      isSuccess: successAdd,
      isError: errorAddS,
    },
  ] = useAddPromoCodeMutation()

  // errorAdd && logoutHandler(errorAdd)

  const [
    editPromoCode,
    { isLoading: loadingEdit, error: errorEdit, isSuccess: successEdit },
  ] = useEditPromoCodeMutation()

  // errorEdit && logoutHandler(errorEdit)

  const columns = [
    {
      headerName: 'Code Name',
      field: 'promoCodeName',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      headerName: 'Code',
      field: 'promoCode',
      flex: 1,
    },
    {
      headerName: 'Code Description',
      field: 'promoCodeDescription',
      flex: 1,
    },
    {
      field: 'promoCodeValue',
      headerName: 'Cost',
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          Rs. {params?.row?.promoCodeValue}
        </Typography>
      ),
    },
    {
      field: 'promoCodeExpiraryDate',
      headerName: 'Expirary Date',
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {moment(params.row.promoCodeExpiraryDate).format('Do MMM YYYY')}
        </Typography>
      ),
    },
    {
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <EditOutlinedIcon onClick={() => editHandler(params)} />
          </>
        )
      },
    },
  ]

  const addButtonHandler = () => {
    setPromoCode(null) //to remove previous state
    handleModalOpen()
  }

  const editHandler = (data) => {
    setPromoCode(data)
    handleModalOpen()
  }

  const submitHandler = async (data) => {
    promoCode
      ? await editPromoCode({
          promoCodeId: promoCode.row._id,
          promoCodeName: data.promoCodeName,
          promoCode: data.promoCode,
          promoCodeValue: data.promoCodeValue,
          promoCodeDescription: data.promoCodeDescription,
          promoCodeExpiraryDate: data.promoCodeExpiraryDate,
        }).unwrap()
      : await addPromoCode({
          promoCodeName: data.promoCodeName,
          promoCode: data.promoCode,
          promoCodeValue: data.promoCodeValue,
          promoCodeDescription: data.promoCodeDescription,
          promoCodeExpiraryDate: data.promoCodeExpiraryDate,
        }).unwrap()
  }

  useEffect(() => {
    if (errorEdit) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and promo code can not be updated',
      })
      handleSnackbarClick()
    }

    if (successEdit) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Promo code has been successfully Updated',
      })
      handleSnackbarClick()
    }

    if (errorAdd) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and promo code can not be added',
      })
      handleSnackbarClick()
    }
    if (errorAddS) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Please fill all the value',
      })
      handleSnackbarClick()
    }
    if (successAdd) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Promo code has been successfully added',
      })
      handleSnackbarClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorEdit, successEdit, errorAdd, errorAddS, successAdd])

  return (
    <Box m='20px'>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleSnackbarClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      {loading ? (
        <>
          <Skeleton animation='wave' variant='h3' />
          <Skeleton animation='wave' variant='body' />
        </>
      ) : (
        <PromoCodeTab modalOpen={addButtonHandler} />
      )}
      {loading ? (
        <>
          <Skeleton variant='rectangular' width='100%'>
            <div style={{ paddingTop: '37%', marginTop: '2%' }} />
          </Skeleton>
        </>
      ) : (
        <Box display='flex' justifyContent='space-between'>
          {/* PromoCode */}
          <Box flex='1 1 100%' ml='15px'>
            <Box
              height='75vh'
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .name-column--cell': {
                  color: colors.greenAccent[300],
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: colors.primary[400],
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: colors.blueAccent[700],
                },
                '& .MuiCheckbox-root': {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                autoHeight={true}
                key={rawPromoCodes?._id}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                rows={rawPromoCodes}
                getRowId={(rawPromoCodes) => rawPromoCodes?._id}
                columns={columns}
                checkboxSelection
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              {promoCode ? 'Edit Promo Code' : 'Add Promo Code'}
            </Typography>
            <PromoForm
              onSubmit={submitHandler}
              cancel={handleModalClose}
              promoCode={promoCode}
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default PromoCode
