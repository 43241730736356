import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { Grid, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const MainButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const CustomerRegistrationForm = ({ registerData, errorMessage }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')

  return (
    <form onSubmit={handleSubmit(registerData)}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12}>
          <TextField
            variant='outlined'
            required
            fullWidth
            id='lastName'
            label='Full Name'
            name='fullName'
            {...register('name', {
              required: 'This is required.',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            {...register('email', {
              required: 'This is required.',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            {...register('password', {
              required: 'This is required.',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value='allowExtraEmails' color='primary' />}
            label='I want to receive inspiration, marketing promotions and updates via email.'
          />
        </Grid>
      </Grid>
      <MainButton type='submit' fullWidth variant='contained' color='primary'>
        Sign Up
      </MainButton>
      <Grid container justifyContent='center'>
        <Grid item>
          <Link onClick={() => navigate('/sign-in')} variant='body2'>
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

export default CustomerRegistrationForm
