import React from 'react'
const TopFrame = () => {
  return (
    <section className='u-clearfix u-section-1' id='carousel_6cec'>
      <div className='u-clearfix u-sheet u-sheet-1'>
        <img
          className='u-image u-image-round u-radius-50 u-image-1'
          src={`../../assets/stomer-desires-portrait-good-looking-european-businesswoman-blue-blouse-glasses-holding-hands-pockets-smiling-being-friendl.jpg`}
          alt=''
          data-image-width='1500'
          data-image-height='1000'
        />
        <img
          className='u-image u-image-round u-radius-50 u-image-2'
          src={`../../assets/3730262_73938.jpg`}
          alt=''
          data-image-width='1669'
          data-image-height='1080'
        />
        <img
          className='u-image u-image-round u-radius-50 u-image-3'
          src={`../../assets/pexels-christina-morillo-1181395.jpg`}
          alt=''
          data-image-width='721'
          data-image-height='1080'
        />
        <img
          className='u-image u-image-round u-preserve-proportions u-radius-32 u-image-4'
          src={`../../assets/sips-about-latest-news-indicates-both-index-fingers-upwards-hears-surprising-news-opens-mouth-wears-big-round-glasses-turtl.jpg`}
          alt=''
          data-image-width='1080'
          data-image-height='1080'
        />
        <img
          className='u-image u-image-round u-preserve-proportions u-radius-32 u-image-5'
          src={`../../assets/e-cheerful-man-looks-happily-away-smiles-broadly-wears-casual-black-t-shirt-isolated-white-background-with-blank-copy-space.jpg`}
          alt=''
          data-image-width='1500'
          data-image-height='1000'
        />
        <img
          className='u-image u-image-round u-radius-50 u-image-6'
          src={`../../assets/cheerful-business-woman-make-winner-gesture.jpg`}
          alt=''
          data-image-width='1620'
          data-image-height='1080'
        />
        <h2 className='u-custom-font u-font-montserrat u-text u-text-default u-text-1'>
          Frame Your
          <br />
          Tiles <span className='u-text-palette-3-base'>On Wall</span>
        </h2>
      </div>
    </section>
  )
}

export default TopFrame
