import React from 'react'
import { useForm } from 'react-hook-form'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { apiSlice } from '../../state/apiSlice'
import { removeCredentials } from '../../state/authSlice'
import { useAuth } from '../../hooks/useAuth'
import { useDispatch } from 'react-redux'
import { Avatar, Grid, Menu, MenuItem, Tooltip, TextField } from '@mui/material'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FilterFramesIcon from '@mui/icons-material/FilterFrames'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import HistoryIcon from '@mui/icons-material/History'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import { tokens } from '../../theme'

const drawerWidth = 240

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: 'white',
  color: 'black',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'white',
    color: 'black',
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const Header = ({ onSubmit }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  const opened = Boolean(anchorEl)
  const { user } = useAuth()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logoutHandler = () => {
    dispatch(removeCredentials())
    dispatch(apiSlice.util.resetApiState())
    navigate('/sign-in')
  }

  const profileHandler = () => {
    navigate(`/customer/profile/${user._id}`)
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      promoCode: '',
    },
  })

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' open={open}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={8} md={11}>
                {' '}
                <Typography
                  variant='h4'
                  color={colors.greenAccent[500]}
                  align='center'
                  sx={{
                    mt: 1,
                    fontWeight: 'bold',
                  }}
                >
                  Memory Frame
                </Typography>
              </Grid>

              <Grid item xs={4} md={1}>
                {user && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'right',
                        textAlign: 'right',
                        float: 'right',
                      }}
                    >
                      <Tooltip title='Account settings'>
                        <IconButton
                          onClick={handleClick}
                          size='small'
                          sx={{ ml: 2 }}
                          aria-controls={opened ? 'account-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={opened ? 'true' : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {user
                              ? user.name.substring(0, 1).toUpperCase()
                              : 'M'}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      id='account-menu'
                      open={opened}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem
                        onClick={() => {
                          profileHandler()
                        }}
                        sx={{
                          margin: '0px',
                        }}
                      >
                        <Avatar
                          style={{
                            height: '30px',
                            width: '30px',
                            marginRight: '10px',
                          }}
                        />{' '}
                        Edit Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          navigate('/customer/promocode-list/Open')
                        }
                        sx={{
                          margin: '0px',
                        }}
                      >
                        <LoyaltyIcon
                          color='primary'
                          style={{
                            height: '30px',
                            width: '30px',
                            marginRight: '10px',
                          }}
                        />{' '}
                        My Promo Codes
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate('/customer/giftcard-list/Open')}
                        sx={{
                          margin: '0px',
                        }}
                      >
                        <CardGiftcardIcon
                          color='primary'
                          style={{
                            height: '30px',
                            width: '30px',
                            marginRight: '10px',
                          }}
                        />{' '}
                        My Gift Cards
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant='temporary'
          anchor='left'
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <CloseIcon /> : <CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {user ? (
            <List>
              <Box mb='25px'>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <img
                    alt='profile-user'
                    width='80px'
                    height='80px'
                    src={`../../assets/user.png`}
                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                  />
                </Box>
                <Box
                  textAlign='center'
                  onClick={() => {
                    logoutHandler()
                  }}
                >
                  <ListItemText primary={`${user.name}`} secondary={'Logout'} />
                </Box>
              </Box>
            </List>
          ) : (
            <>
              <Typography
                variant='subtitle2'
                noWrap
                component='div'
                sx={{ p: 2 }}
              >
                <b>Sign up</b> to save your progress <br />& track orders
              </Typography>
              <Button
                variant='contained'
                sx={{ mb: 1, mx: 3 }}
                onClick={() => navigate('/sign-in')}
              >
                Sign Up / Sign In
              </Button>
            </>
          )}

          <Divider />
          <List>
            <ListItem key={1} disablePadding>
              <ListItemButton onClick={() => navigate('/customer/frame-photo')}>
                <ListItemIcon>
                  <FilterFramesIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={'Frame Your Photo'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={2} disablePadding>
              <ListItemButton
                onClick={() => navigate('/customer/giftcard/Tiles3')}
              >
                <ListItemIcon>
                  <CardGiftcardIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={'Buy a Gift Card'} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={3} disablePadding>
              <ListItemButton onClick={handleModalOpen}>
                <ListItemIcon>
                  <LoyaltyIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={'Add Promo Code'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={4} disablePadding>
              <ListItemButton
                onClick={() => navigate('/customer/order-list/Open')}
              >
                <ListItemIcon>
                  <HistoryIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={'My Orders'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={5} disablePadding>
              <ListItemButton onClick={() => navigate('/customer/faq')}>
                <ListItemIcon>
                  <ContactSupportIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={'Frequent Questions'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open} sx={{ p: 0 }}>
          <DrawerHeader />
          {/* <Outlet /> */}
        </Main>
      </Box>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Add Promo Code
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: 500,
                      maxWidth: '100%',
                      mt: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      label='Your Code'
                      id='fullWidth'
                      type='text'
                      placeholder='Your Promo Code'
                      {...register('promoCode', {})}
                    />
                  </Box>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default Header
