import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { CssBaseline, ThemeProvider, Snackbar, Alert } from '@mui/material'
import { ColorModeContext, useMode, useAdminMode } from './theme'
import { AdminRoute } from './utils/AdminRoute'
import { useAuth } from './hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import Topbar from './screens/global/Topbar'
import Sidebar from './screens/global/Sidebar'
import Dashboard from './screens/dashboard'
import PromoCode from './screens/promo-code'
import HomeScreen from './screens'
import Order from './screens/order'
import CustomerOrder from './screens/customer/order'
import OrderDetail from './screens/order/detail'
import FAQ from './screens/frequent'
// import GiftCard from './screens/gift-card'
import AdminLoginScreen from './screens/login'
import Header from './components/homePage/Header'
import { PrivateRoute } from './utils/PrivateRoute'
import CustomerLoginScreen from './screens/customer/login'
import CustomerRegisterScreen from './screens/customer/register'
import { useAddCustomerPromoCodeMutation } from './state/promoCodeSlice'
import CustomerPromoCodeList from './screens/customer/promo-code'
import PhotoFrameScreen from './screens/customer/image-upload'
import CustomerGiftCardList from './screens/customer/gift-card'
import CustomerGiftCard from './screens/customer/gift-card/gift-card'
import AdminFAQ from './screens/faq'
import CustomerForgetPasswordScreen from './screens/customer/forget-password'
import CustomerResetPasswordScreen from './screens/customer/reset-password'
import GiftPhotoFrameScreen from './screens/customer/image-upload/gift-photoframe'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<HomeLayout />} />
          <Route path='/sign-in' element={<CustomerLoginScreen />} />
          <Route path='/sign-up' element={<CustomerRegisterScreen />} />
          <Route
            path='/forget-password'
            element={<CustomerForgetPasswordScreen />}
          />
          <Route
            path='/reset-password/:id/:token'
            element={<CustomerResetPasswordScreen />}
          />
          <Route path='/admin-login' element={<AdminLoginScreen />} />
          <Route path='/customer/*' element={<CustomerLayout />} />
          <Route path='/admin/*' element={<AdminLayout />} />
        </Routes>
      </Router>
    </>
  )
}

function AdminLayout() {
  const [theme, colorMode] = useAdminMode()
  const [isSidebar, setIsSidebar] = useState(true)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          <Sidebar isSidebar={isSidebar} />
          <main className='content'>
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route
                path='/dashboard'
                element={<AdminRoute component={Dashboard} exact />}
              />
              <Route
                path='/order-list/:status'
                element={<AdminRoute component={Order} exact />}
              />
              <Route
                path='/order-details/:id'
                element={<AdminRoute component={OrderDetail} exact />}
              />
              <Route
                path='/promo-code-list/:status'
                element={<AdminRoute component={PromoCode} exact />}
              />
              {/* <Route
                path='/gift-cards/:status'
                element={<AdminRoute component={GiftCard} exact />}
              /> */}
              <Route
                path='/faq'
                element={<AdminRoute component={AdminFAQ} exact />}
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

function CustomerLayout() {
  const [theme, colorMode] = useMode()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [snackBarProps, setSnackBarProps] = useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [addPromoCodeUser, { isSuccess: success, error, isError }] =
    useAddCustomerPromoCodeMutation()

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const submitHandler = async (data) => {
    if (user) {
      await addPromoCodeUser({
        promoCode: data.promoCode,
      }).unwrap()
    } else {
      navigate('/sign-in')
    }
  }

  useEffect(() => {
    if (error) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and promocode can not be added',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Prome code value is required',
      })
      handleClick()
    }

    if (success) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Promo code has been successfully added',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [error, success, isError])

  return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <main className='content'>
          <Header onSubmit={submitHandler} />
          <Snackbar
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            open={openSnackbar}
            onClick={handleClose}
            autoHideDuration={6000}
          >
            <Alert
              onClick={handleClose}
              severity={`${snackBarProps.alertType}`}
              sx={{ width: '100%' }}
              variant='filled'
            >
              {`${snackBarProps.message}`}
            </Alert>
          </Snackbar>
          <Routes>
            <Route
              path='/order-list/:status'
              element={<PrivateRoute component={CustomerOrder} exact />}
            />
            <Route
              path='/promocode-list/:status'
              element={<PrivateRoute component={CustomerPromoCodeList} exact />}
            />
            <Route
              path='/giftcard-list/:status'
              element={<PrivateRoute component={CustomerGiftCardList} exact />}
            />
            <Route
              path='/frame-photo/:id/:noTiles'
              element={<PrivateRoute component={GiftPhotoFrameScreen} exact />}
            />
            <Route
              path='/giftcard/:status'
              element={<CustomerGiftCard />}
              exact
            />
            <Route path='/frame-photo' element={<PhotoFrameScreen />} exact />
            <Route path='/faq' element={<FAQ exact />} />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  )
}

function HomeLayout() {
  const [addPromoCodeUser] = useAddCustomerPromoCodeMutation()
  const [theme, colorMode] = useMode()

  const submitHandler = async (data) => {
    await addPromoCodeUser({
      promoCode: data.promoCode,
    }).unwrap()
  }
  return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <Header onSubmit={submitHandler} />
        <main className='content'>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  )
}

export default App
