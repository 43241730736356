import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import TabContext from '@mui/lab/TabContext'
import { Box, Tabs, Tab, useTheme } from '@mui/material'
import Header from '../../components/Header'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import BackspaceIcon from '@mui/icons-material/Backspace'
import CachedIcon from '@mui/icons-material/Cached'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'

const tabValues = {
  Open: 'Open',
  OnDelivery: 'OnDelivery',
  Cancelled: 'Cancelled',
  Delivered: 'Delivered',
}

const OrderTab = ({ onSubmit }) => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { user } = useAuth()

  const [value, setValue] = useState('Open')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(value)
  }, [dispatch, value])

  // making sure the tabs are reactive to the "status" params change in the url
  React.useEffect(() => {
    if (tabValues[params.status] !== undefined) {
      setValue(tabValues[params.status])
    } else {
      setValue('Open')
    }
  }, [params.status])

  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='Order' subtitle='List of Order' />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)'>
        <Box gridColumn='span 12' gridRow='span 1'>
          <Box
            mt='5px'
            display='flex '
            justifyContent='space-between'
            alignItems='center'
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: 'divider',
                  marginBottom: '15px',
                }}
              >
                {user.role === 'Admin' && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='Orders'
                  >
                    <Tab
                      icon={<BeenhereIcon />}
                      label='Open'
                      value='Open'
                      onClick={() => navigate('/admin/order-list/Open')}
                    />
                    <Tab
                      icon={<CachedIcon />}
                      label='On-Delivery'
                      value='OnDelivery'
                      onClick={() => navigate('/admin/order-list/OnDelivery')}
                    />
                    <Tab
                      icon={<BackspaceIcon />}
                      label='Cancelled'
                      value='Cancelled'
                      onClick={() => navigate('/admin/order-list/Cancelled')}
                    />
                    <Tab
                      icon={<LocalShippingOutlinedIcon />}
                      label='Delivered'
                      value='Delivered'
                      onClick={() => navigate('/admin/order-list/Delivered')}
                    />
                  </Tabs>
                )}
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrderTab
