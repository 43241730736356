import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import { useRegisterUserMutation } from '../../../state/userSlice'
import { useLoginMutation } from '../../../state/apiSlice'
import { setCredentials } from '../../../state/authSlice'
import { useAuth } from '../../../hooks/useAuth'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import CustomerRegistrationForm from './form'

const MainDiv = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const MainAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}))

const CustomerRegisterScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuth()

  const [errorRegisterMessage, setErrorRegisterMessage] = useState(null)

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (user) {
      navigate(redirect)
    }
  }, [navigate, user, redirect])

  const [registerUser, { isLoading: loadingRegister }] =
    useRegisterUserMutation()

  const [login, { isLoading: loadingLogin }] = useLoginMutation()

  const registerHandler = async (data) => {
    await registerUser(data)
      .unwrap()
      .then((payload) => {
        // to login registered user
        login(data)
          .unwrap()
          .then((payload) => {
            dispatch(setCredentials(payload))
          })
          .catch((error) => {
            error && setErrorRegisterMessage(error.data.message)
          })
      })
      .catch((error) => {
        // console.error('rejected', error)
        error && setErrorRegisterMessage(error.data.message)
      })
  }
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <MainDiv sx={{ p: 3 }}>
        <MainAvatar>
          <LockOutlinedIcon />
        </MainAvatar>
        <Typography component='h1' variant='h5'>
          Registration
        </Typography>
        <CustomerRegistrationForm
          registerData={registerHandler}
          errorMessage={errorRegisterMessage}
        />
      </MainDiv>
    </Container>
  )
}

export default CustomerRegisterScreen
