import React, { useEffect } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, useTheme, Snackbar, Alert, Grid } from '@mui/material'
import { tokens } from '../../../theme'
import CustomerGiftCardAddTab from './gift-cardtab'
import { useAddGiftCardMutation } from '../../../state/giftCardSlice'

const CustomerGiftCard = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const { user } = useAuth()

  const navigate = useNavigate()
  const params = useParams()

  const [snackBarProps, setSnackBarProps] = React.useState({
    alertType: '',
    message: '',
  })
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const status = params.status

  const tilesno = status.slice(5)

  const [
    addGiftCard,
    { isLoading: loadingAdd, error: errorAdd, isSuccess: success, isError },
  ] = useAddGiftCardMutation()

  const handleClick = () => {
    setOpenSnackbar(true)
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  const submitHandler = async (data) => {
    if (user) {
      await addGiftCard({
        senderName: data.senderName,
        senderEmail: data.senderEmail,
        receiverEmail: data.receiverEmail,
        noTiles: tilesno,
        amount: 1500 * tilesno,
        shippingIncluded: true,
        paid: 'Paid',
      }).unwrap()
    } else {
      navigate('/sign-in')
    }
  }

  useEffect(() => {
    if (errorAdd) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Something went wrong and your gift card cannot be sent',
      })
      handleClick()
    }

    if (isError) {
      setSnackBarProps({
        alertType: 'error',
        message: 'Email address for gift card receiver required',
      })
      handleClick()
    }

    if (success) {
      console.log('first')
      setSnackBarProps({
        alertType: 'success',
        message: 'Gift card successfully sent to the email',
      })
      handleClick()
    }

    return () => {
      setSnackBarProps({
        alertType: '',
        message: '',
      })
    }
  }, [errorAdd, success, isError])

  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={openSnackbar}
        onClick={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          onClick={handleClose}
          severity={`${snackBarProps.alertType}`}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {`${snackBarProps.message}`}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xl={6} lg={6} md={5} sm={12} xs={12} key={1}>
          <img
            src={`../../assets/gift-screen.jpg`}
            alt='frame-image'
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={7} sm={12} xs={12} key={2}>
          <CustomerGiftCardAddTab onSubmit={submitHandler} />
        </Grid>
      </Grid>
      {/* )} */}
    </Box>
  )
}

export default CustomerGiftCard
