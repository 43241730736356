import React from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { Grid, Link } from '@mui/material'

const MainButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const CustomerLoginForm = ({ loginData, errorMessage }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  return (
    <form onSubmit={handleSubmit(loginData)}>
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email Address'
        name='email'
        autoFocus
        {...register('email', {
          required: 'This is required.',
        })}
      />
      {errors.email && <p className='error no-space'>{errors.email.message}</p>}
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        {...register('password', {
          required: 'This is required.',
        })}
      />
      {errors.password && (
        <p className='error no-space'>{errors.password.message}</p>
      )}
      <FormControlLabel
        control={<Checkbox value='remember' color='primary' />}
        label='Remember me'
      />
      <MainButton type='submit' fullWidth variant='contained' color='primary'>
        Sign Up
      </MainButton>
      <Grid container justifyContent='center' sx={{ mb: 1 }}>
        <Grid item>
          <Link onClick={() => navigate('/sign-up')} variant='body2'>
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' sx={{ mb: 1 }}>
        <Grid item>
          <Link onClick={() => navigate('/forget-password')} variant='body2'>
            {'Forget Password ? Need help'}
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

export default CustomerLoginForm
