import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tabs, Tab } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { useDispatch } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import Header from '../../../components/Header'

const tabValues = {
  Open: 'Open',
  Used: 'Used',
  Expired: 'Expired',
}

const CustomerPromoCodeTab = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const [value, setValue] = useState('Open')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(value)
  }, [dispatch, value])

  // making sure the tabs are reactive to the "status" params change in the url
  React.useEffect(() => {
    if (tabValues[params.status] !== undefined) {
      setValue(tabValues[params.status])
    } else {
      setValue('Open')
    }
  }, [params.status])

  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='Promo Code' subtitle='List of Promocode' />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)'>
        <Box gridColumn='span 12' gridRow='span 1'>
          <Box
            mt='5px'
            display='flex '
            justifyContent='space-between'
            alignItems='center'
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: 'divider',
                  marginBottom: '15px',
                }}
              >
                <Tabs value={value} onChange={handleChange} aria-label='Orders'>
                  <Tab
                    icon={<CachedIcon />}
                    label='Open'
                    value='Open'
                    onClick={() => navigate('/customer/promocode-list/Open')}
                  />
                  <Tab
                    icon={<CachedIcon />}
                    label='Used'
                    value='Used'
                    onClick={() => navigate('/customer/promocode-list/Used')}
                  />
                  <Tab
                    icon={<BackspaceIcon />}
                    label='Expired'
                    value='Expired'
                    onClick={() => navigate('/customer/promocode-list/Expired')}
                  />
                </Tabs>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerPromoCodeTab
