import React from 'react'
import { useForm } from 'react-hook-form'
import { Box, Grid, TextField, Button } from '@mui/material'

const PromoForm = ({ onSubmit, cancel, promoCode }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      promoCodeName: promoCode ? promoCode.row.promoCodeName : '',
      promoCode: promoCode ? promoCode.row.promoCode : '',
      promoCodeValue: promoCode ? promoCode.row.promoCodeValue : '',
      promoCodeDescription: promoCode ? promoCode.row.promoCodeDescription : '',
      promoCodeExpiraryDate: promoCode
        ? promoCode.row.promoCodeExpiraryDate
        : '',
    },
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Promo Code Name'
              id='fullWidth'
              type='text'
              placeholder='Promo Code Name'
              {...register('promoCodeName', {})}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Promo Code'
              id='fullWidth'
              type='text'
              placeholder='Promo Code'
              {...register('promoCode', {})}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Promo Code Cost'
              id='fullWidth'
              type='decimal'
              placeholder='Promo Code Cost'
              {...register('promoCodeValue', {})}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Promo Code Discription'
              id='fullWidth'
              type='text'
              placeholder='Promo Code Discription'
              {...register('promoCodeDescription', {})}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label='Your Code'
              id='fullWidth'
              type='date'
              placeholder='Promo Code Expiry Date'
              {...register('promoCodeExpiraryDate', {})}
            />
          </Box>
        </Grid>
      </Grid>
      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </form>
  )
}

export default PromoForm
