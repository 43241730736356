import React from 'react'
import { Box, Button, useTheme } from '@mui/material'
import Header from '../components/Header'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { tokens } from '../theme'
import { useGetCustomerFaqsQuery } from '../state/faqSlice'

const FAQ = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const {
    data: rawFaq = [],
    isLoading: loading,
    error,
  } = useGetCustomerFaqsQuery()
  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='FAQ' subtitle='Frequently Asked Questions Page' />
      </Box>

      {rawFaq.map((event, i) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colors.greenAccent[500]} variant='h5'>
              {event?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{event?.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default FAQ
